import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("产品条码"),
    dataIndex: "material_barcode",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("计费单位"),
    dataIndex: "charge_unit_display",
    width: 100,
    ellipsis: true,
  },
  {
    title: T("入库配送单价(元)"),
    dataIndex: "stock_in_transport_unit_price",
    width: 200,
    ellipsis: true,
  },
  {
    title: T("入库卸货单价(元)"),
    dataIndex: "stock_in_handling_unit_price",
    width: 200,
    ellipsis: true,
  },
  {
    title: T("出库配送单价(元)"),
    dataIndex: "stock_out_transport_unit_price",
    width: 210,
    ellipsis: true,
  },
  {
    title: T("出库装车单价(元)"),
    dataIndex: "stock_out_handling_unit_price",
    width: 210,
    ellipsis: true,
  },
  {
    title: T("仓储单价(元)"),
    dataIndex: "storage_unit_price",
    width: 200,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 100,
  },
];
